import { graphql, PageProps } from 'gatsby';
import React, { FC } from 'react';

import Layout from 'src/views/components/layout/default';
import SEO from 'src/views/components/seo-tags';
import {
  LabelsAndMessages,
} from 'src/infrastructure/gatsby/types/label-and-messages';
import PageContextProvider from '../templates/context';

type DataProps = {
  labelsAndMessages: Partial<LabelsAndMessages>;
}

const NotFoundPage: FC<PageProps<DataProps>> = ({ location, data }) => (
  <PageContextProvider
    locale="en"
    translationLinks={[]}
    pageSettings={{
      id: '404page',
      language: 'en',
      slug: '404',
      subpath: null,
      publishStatus: true,
      parentPageTitle: null,
      parentPagePath: null,
    }}
    title="Not Found"
    labelsAndMessages={data.labelsAndMessages}
  >
    <Layout location={location}>
      <SEO
        metaTitle="404: Not Found"
        metaDescription="Page not found"
        location={location}
        pageType="website"
        translationLinks={[]}
      />
      <div style={{ textAlign: 'center' }}>
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  </PageContextProvider>
);

export const pageQuery = graphql`
  query {
    labelsAndMessages {
      en
    }
  }
`;

export default NotFoundPage;
